import React,{useState,useEffect} from 'react';
import Pagination from "react-js-pagination";

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Input 
} from '@chakra-ui/react'
import CustomModal from '../components/CustomModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../helpers';
import moment from 'moment';
import Header from '../components/Header'
import { getUserFromToken } from '../components/authHelper';
import CreateWebHook from './CreateWebHook';

function List() {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [list , setList]= useState([])
  const [date , setDate]= useState('')

  const [loading,setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 

  const [customer , setCustomer]= useState('')


  useEffect(()=>{
     const user = getUserFromToken();
     setCustomer(user)
    const id = user.customer_id   
    loadlist(id,date);

   
  },[date])

  const loadlist = async(id,date="")=>{
    setLoading(true)
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : "";
    
    const url = `${process.env.REACT_APP_API_URL}/msg-log/list?customer_id=${id}&date=${formattedDate}`;

   

    const data  = await fetchWrapper.get(url)
    setList(data.data);
    setListCount(data.count)
    setLoading(false)
  }

   const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      // console.log(pageNumber);
  };

    const meClose = () => {
    
     onClose();    
  }


  return(
    <>

    {loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
    <Header/>
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Message Log</Text>
          </Box>



          <Box>
            <HStack>
              <Box>
                <CustomModal
                  showModalButtonText="Configure DLR Web Hook"
                  modalHeader="Web Hook"
                  isOpen={isOpen}
                  onClose={onClose}
                  onOpen={onOpen}
                  modalBody=<CreateWebHook meClose={meClose} customer={customer} loadList={loadlist}  />
                />
              </Box>
              <Box>
                <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
              </Box>

             </HStack>
            
          </Box>
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Message ID</Th>
                  <Th>Country</Th>
                  <Th>Operator</Th>
                  <Th>Sent to</Th>
                  <Th>Message</Th>
                  <Th>Date & Time</Th>
                  <Th>Status</Th>
                  <Th></Th>



                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                  {list.map((rs,index)=>{
                    return(
                      <>
                          <Tr> 
                            <Td>{rs.message_id}</Td>
                           
                            <Td>{rs?.Country?.name}</Td>
                            <Td>{rs?.Operator?.name}</Td>
                            <Td> +{rs.sent_to}</Td>
                            
                            <Td>{rs.message}</Td>
                            <Td>
                              <Text>Received: {moment(rs.receive_date).format('DD/MM/YY | HH:mm')}</Text>
                              <Text>Sent: {moment(rs.sent_date).format('DD/MM/YY | HH:mm')}</Text>
                            </Td>

                            

                            <Td>
                              {rs?.Status?.name}
                            </Td>
                            
                            <Td style={{color:"#0000EE"}}>
                              <Menu>
                                <MenuButton fontSize="14px" >
                                Action
                                  
                                    <ChevronDownIcon />
                                </MenuButton>

                                <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                  <MenuItem  h="16px" fontSize="12px">Edit</MenuItem>
                                  
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                      </>
                    )
                  })}

               
                            
                      
            
              </Tbody>
             
              
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default List;