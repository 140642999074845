import "prismjs/themes/prism-tomorrow.css";

import React, {useEffect} from 'react';

import Prism from 'prismjs';
import "prismjs/components/prism-json";

const Veiw  = ({ type ,rs })=>{
	let responsedata;

	if (type === "veiw_data") {
	  responsedata = JSON.parse(rs?.data_posted);
	} else {
	  responsedata = JSON.parse(rs?.data_response);
	}

	  useEffect(() => {
	    
	    Prism.highlightAll();
	  }, []);
	return (
		<>
			<pre style={{ backgroundColor: 'black', textShadow: 'none', color: 'white', padding: '0px', borderRadius: '8px' }}>
              <span style={{padding: 15,backgroundColor: '#2F2F2F',fontSize: '14px', display: 'block', marginBottom: '10px' }}>json</span>
              <code className="language-json" style={{padding: '0px 15px 15px',display: 'block' }}>
                {JSON.stringify(responsedata, null, 2)}
              </code>
            </pre>
		</>
	)
}


export default Veiw;