import React,{useState,useEffect} from 'react';
import { HStack,
	  Box,
	  Image,
	  Text,
	  Menu,
	  MenuButton,
	  MenuList,
	  MenuItem,
	  Button } from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons'


import Headerlogo from '../images/Logo.png';
import userimg from '../images/user_img.png';
import { Link ,useLocation,useNavigate} from "react-router-dom";
import "../index.css";

function Header(){

	  const [email, setEmail] = useState('');
	  
	  const location = useLocation();
	  const navigate = useNavigate()
	

	useEffect(() => {
	    // Check if the token is present in local storage
	    const token = localStorage.getItem('customer-portal');


	    if (token) {
	      const decodedToken = decodeToken(token);
	      const Userdata = decodedToken.userdata;
	      // console.log(Userdata)

	      // Set the email state
	      setEmail(Userdata.email);
	      
	    }
	  }, []);

	const decodeToken = (token) => {
	    const decodedString = atob(token.split('.')[1]);
	    const decodedObject = JSON.parse(decodedString);
	    // console.log(decodedObject)
	    return decodedObject;
	  };

	  const Logout = ()=>{
	  	localStorage.removeItem('customer-portal')
	  	navigate("/")
	  }

	  const link = (view)=>{
		if(view == 'sms-send'){
			navigate("/api-doc")
		}
		if(view == 'web-hook'){
			navigate("/web-hook")
		}
	}
	
	
	const isActive = location.pathname.includes('/api-doc') || location.pathname.includes('/web-hook');

	return(
		<>
		<HStack px="70px" py="10px" justifyContent="space-between">
		<Box>
		<Link to="/dashboard">
			<Image src={Headerlogo} alt='VerifyApp' w="auto" h="30px"  />
		</Link>
			
				
		</Box>
		<Box>
			<HStack>
				<Box>
					<Menu>
					  <MenuButton textAlign="left"   fontSize="14px" >
					  <Text mb="0">Welcome</Text>
					  <HStack id="Header_User">
					
					  <Box>{email}</Box>
					  <Box><ChevronDownIcon boxSize={6}  /></Box>

					

					  
					  
					      
					  </HStack>
					    
					  </MenuButton>

					  <MenuList>
					   
					    <MenuItem onClick={()=> Logout()}>Logout</MenuItem>
					    
					  </MenuList>

					</Menu>
				</Box>
			

				<Box>
				
					<Image src={userimg}  style={{borderRadius:"50%"}} w="48.54px" h="48px"  />
				</Box>
			
			</HStack>
		</Box>
		
		</HStack>
		
		<HStack h="50px" bg="#F5F5F5" py="10px" px="50px" justifyContent="space-between" color="#000000" fontSize="14px" className="header_section">

			

			<Box ml="20px">
				<HStack>
					<Box me="30px">
						<Link to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""} >Dashboard</Link>
					</Box>


					<Box me="30px">
						<Link to="/delivery-report" className={location.pathname === "/delivery-report" ? "active" : ""} >Delivery Report</Link>
					</Box>

					<Box me="30px">
						<Link to="/billing" className={location.pathname === "/billing" ? "active" : ""} >Billing</Link>
					</Box>

					<Box me="30px">
						<Link to="/webhook" className={location.pathname === "/webhook" ? "active" : ""} >Webhook Logs</Link>
					</Box>


					<Box me="30px">
						<Link to="/user" className={location.pathname === "/user" ? "active" : ""} >Users</Link>
					</Box>
					

					<Box>
						<Menu>
						  <MenuButton textAlign="left"   fontSize="14px" >
						  <HStack id="Header_User">
						
						 <Box className={isActive ? "active" : ""}>Documentation</Box>
						  <Box><ChevronDownIcon boxSize={6}  /></Box>

						

						  
						  
						      
						  </HStack>
						    
						  </MenuButton>

						  <MenuList>
						    <MenuItem mb="2px"  onClick={()=> link('sms-send')}> 
						    	<Link className={location.pathname === "/api-doc" ? "active" : ""} >Message Send API</Link>
						    </MenuItem>
						    <hr />
						    <MenuItem mb="2px" onClick={()=> link('web-hook')}>
						    	<Link className={location.pathname === "/web-hook" ? "active" : ""}>Web Hooks</Link>
						    </MenuItem>
						    
						  </MenuList>

						</Menu>
					</Box>

					


				</HStack>
			</Box>
		</HStack>
		</>
	);
}

export default Header;