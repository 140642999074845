import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';

function ChangePassword ({meCloseEdit,loadList,rs}){
	
	

	const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    id :rs.id,
    
    
  });

  const FormSchema = Yup.object().shape({
	   password: Yup.string().required('Required'),
	   confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match') // Ensure passwords match
      .required('Required'),
	 	
	});

  

	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		    let data = { ...values };
    		delete data.confirmPassword;
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/user/password-change`;
  		await fetchWrapper.post(url,data)
 			loadList()
  		meCloseEdit();
  		toast(`Record has been update `, {
	       type: "info"
	    });		
	}

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>
					<HStack mb="20px">
							<Box>User ID	 :</Box>
							<Box>
									<Text>{rs.email}</Text>
							</Box>
					</HStack>
					<Box mb="10px">
						<InputText name="password" title="password" inputProps={{type:'password'}} />
					</Box>

					<Box mb="10px">
	          <InputText name="confirmPassword" title="Confirm Password" inputProps={{ type: 'password' }} />
	        </Box>
					
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		 update password
			          		</Button>
				</Form>

		</>
	)
}

export default ChangePassword;