import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../helpers';

function Create ({meClose,loadList,customer}){
	const customer_id = customer?.customer_id
	

	const [formData, setFormData] = useState({
    webhook_url: '',
    customer_id:customer_id,
    method:'Post'
    
    
  });

  const FormSchema = Yup.object().shape({
	   webhook_url: Yup.string().required('Required'),
	   	 	
	});

	useEffect(()=>{
		loadDetail();
	

	},[customer_id])
	const loadDetail = async() =>{
		const url = `${process.env.REACT_APP_API_URL}/customer/by-id?id=${customer_id}`;
      	const data  = await fetchWrapper.get(url)
      	const customer = data?.data
     setFormData({
        webhook_url: customer.webhook_url || '',
        customer_id: customer.id,
        method:'Post'
      });
     
	}

  

	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values;	       
	    const url = `${process.env.REACT_APP_API_URL}/customer-portal/update`;
  		await fetchWrapper.post(url,data)
 		loadList(customer_id)
  		meClose();
  		toast(`Record has been saved `, {
	       type: "info"
	    });		
	}

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>
					<hr style={{border:'1px solid #CCCCCC',marginBottom:'10px'}}/>

					<Box mb="20px">
						<Text fontSize="12px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Keep track of your message deliveries in real-time with our Webhook, automatically posting status updates directly to your system. Stay informed about delivery confirmations and failures instantly.</Text>
					</Box>

					
					
					<Box mb="10px">
						<InputText name="webhook_url" title="URL" placeholder="Enter your URL" />
					</Box>

					<Box mb="10px" className="disabled">
						<InputText name="method" title="Method" inputProps={{ disabled : true}} />
					</Box>

			



					
				 					 
				 	<HStack>
				 		<Box>
				 			<Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		 {formData.webhook_url === "" ? 'Add' : 'Update'}  Web Hook
			          		</Button>
				 		</Box>
				 		<Box>
				 			<Button py="10px" px="25px" color="#000000" bg="#FFFFFF" border="1px" borderColor="#CCCCCC" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            onClick={()=> meClose()}
			          		>
			          		Close
			          		</Button>
				 		</Box>
				 	</HStack>
				 	
				 	
			         
				</Form>

		</>
	)
}

export default Create;