import React from 'react'
import './App.css';
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import DeliveryReport from './pages/DeliveryReport';
import Billing from './pages/Billing';
import User from './pages/User';
import ApiDocumention from './pages/Api-Documention';
import WebHook from './pages/Web-Hook-Document';
import WebHookLogs from './pages/WebHook-Logs'
import { PrivateRoute } from './components';




import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
     <>
    <ChakraProvider>
     
        <Routes>            
          <Route path="/" element={<Login />} />


          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard/>
            </PrivateRoute>
          } />

          <Route path="/delivery-report" element={
            <PrivateRoute>
              <DeliveryReport/>
            </PrivateRoute>
          } />

          <Route path="/billing" element={
            <PrivateRoute>
              <Billing/>
            </PrivateRoute>
          } />

           <Route path="/user" element={
            <PrivateRoute>
              <User/>
            </PrivateRoute>
          } />
          <Route path="/webhook" element={
            <PrivateRoute>
              <WebHookLogs/>
            </PrivateRoute>
          } />

           <Route path="/api-doc" element={
            <PrivateRoute>
              <ApiDocumention/>
            </PrivateRoute>
          } />

          <Route path="/web-hook" element={
            <PrivateRoute>
              <WebHook/>
            </PrivateRoute>
          } />
         
                 
          </Routes>
        
    </ChakraProvider>
   </>
  );
}

export default App;
