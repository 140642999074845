import "prismjs/themes/prism-tomorrow.css";
import Prism from 'prismjs';
import React,{useEffect} from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import List from './user/List'
import Header from '../components/Header'
import configImage from '../images/config.png'
import webhookImage from '../images/web-hook.png'

import { getUserFromToken } from '../components/authHelper';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  MenuDivider,
  Link,
  Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react'
import "prismjs/components/prism-json"; // Ensure JSON support is loaded

function User(){
  

  const responsedata = {
        "messageid": "5e8c48b3-540a-4f43-ac8a-cc2a6a2114d6",        
        "status": "Success",
        "status_details": "Message Sent",
        "date_time": 1727247788
}


	const user = getUserFromToken();
    const id = user.customer_id ;


      useEffect(() => {
        Prism.highlightAll(); 
      }, []);  

	return(
		<>
		<Header/>
		<Box className="body_section" style={{width:'60%'}}>
			<Box mb="20px">
        <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Web Hooks</Text>
      </Box>

      <HStack mb="20px">
        <Box>
          <Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">URL :</Text>
        </Box>
        <Box>
          <Link color="#000000" fontSize="14px" fontWeight="400" my="0px" fontFamily="'Inter', sans-serif">Please enter your Web Hook Receiving End URL</Link>
        </Box>
      </HStack>

      <HStack  mb="20px">
        <Box>
          <Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Method : </Text>
        </Box>
        <Box>
        <Badge borderRadius="30px" fontSize="14px" px="30px" py="5px" fontWeight="600" bg="#FF9A00" color="white" my="0px" fontFamily="'Inter', sans-serif">post</Badge>

        </Box>
      </HStack>

      <Box mb="20px">
      
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">How to Configure Web Hook URL</Text>
          </Box>

          <Box mb="10px">
            <Image src={configImage}/>
          </Box>

          
          <Box mb="20px">
            <Box>
                <HStack>  
                    <Box>
                      <Text>1</Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
                        Click on Delivery Report Page in Main Menu.
                      </Text>
                    </Box>
                </HStack>
            </Box>

            <Box>
                <HStack>  
                    <Box>
                      <Text>2</Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
                       Click on Configure DLR Web Hook. A popup will open
                      </Text>
                    </Box>
                </HStack>
            </Box>  
          </Box>

          <Box mb="10px">
            <Image src={webhookImage}/>
          </Box>

          <Box mb="10px">
            <Box>
                <HStack>  
                    <Box>
                      <Text>3</Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
                        Click on Delivery Report Page in Main Menu.
                      </Text>
                    </Box>
                </HStack>
            </Box>

            <Box>
                <HStack>  
                    <Box>
                      <Text>4</Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
                       Click on Add Web Hook Button to Save the Web Hook.
                      </Text>
                    </Box>
                </HStack>
            </Box>  
          </Box>


      </Box>  



      <Box mb="20px">
        <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Web Hook Payload</Text>
         <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
            Following Payload will be Sent on the Web Hook URL
         </Text>

          <pre style={{ backgroundColor: 'black', textShadow: 'none', color: 'white', padding: '0px', borderRadius: '8px' }}>
              <span style={{padding: 15,backgroundColor: '#2F2F2F',fontSize: '14px', display: 'block', marginBottom: '10px' }}>json</span>
              <code className="language-json" style={{padding: '0px 15px 15px',display: 'block' }}>
                {JSON.stringify(responsedata, null, 2)}
              </code>
            </pre>


      </Box>



        <Box mb="20px">            
       <div className="table_section">
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Web Hook Payload Status</Text>
          </Box>
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Status</Th>
                  <Th>Status Detail</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                <Tr>
                  <Td className="green">Success</Td>
                  <Td className="green">Message Sent</Td>
                  <Td>
                    Message has been sent to Network
                  </Td>
                </Tr>
                <Tr>
                  <Td className="green">Success</Td>
                  <Td className="green">Message Delivered</Td>
                  <Td>
                    Message has been Delivered to Handset.
                  </Td>
                </Tr>
                <Tr>
                  <Td className="red">Failed</Td>
                  <Td className="red">Missing OTP</Td>
                  <Td>
                    OTP was not found in the Message
                  </Td>
                </Tr>
                <Tr>
                  <Td className="red">Failed</Td>
                  <Td className="red">Invalid Phone No</Td>
                  <Td>
                   Phone Number is Not Valid
                  </Td>
                </Tr>
                 <Tr>
                  <Td className="red">Failed</Td>
                  <Td className="red">Delivery Failed</Td>
                  <Td>
                  Delivery Failed by Network
                  </Td>
                </Tr>

              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>            
        </Box>


       {/* <Box mb="20px">            
       <div className="table_section">
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">API Response Code</Text>
          </Box>
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Status</Th>
                  <Th>Status Code</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                <Tr>
                  <Td>accepted</Td>
                  <Td>200</Td>
                  <Td className="line-break">
                    Message has been accepted and will be sent to Customer
                  </Td>
                </Tr>
                <Tr>
                  <Td>Content Missing</Td>
                  <Td className="red">404</Td>
                  <Td>
                    message Field sent in Payload in Blank
                  </Td>
                </Tr>
                <Tr>
                  <Td>Phone Number Missing</Td>
                  <Td className="red">404</Td>
                  <Td>
                    to Field sent in Payload in Blank
                  </Td>
                </Tr>
                <Tr>
                  <Td>Authentication Failed</Td>
                  <Td className="red">404</Td>
                  <Td className="line-break">
                    API Key Missing, Invalid API Key or API Key does not Exist in our Database.
                  </Td>
                </Tr>
                <Tr>
                  <Td>Account Inactive</Td>
                  <Td className="red">404</Td>
                  <Td>
                   Customer Account Mark as Inactive in the System
                  </Td>
                </Tr>
                <Tr>
                  <Td>Insufficient balance</Td>
                  <Td className="red">404</Td>
                  <Td className="line-break">
                   Customer do not have enough balance available or has reached their credit limit
                  </Td>
                </Tr>
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>            
        </Box>*/}


        <Box mb="20px">
       <div className="table_section">
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Other Information</Text>
          </Box>
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Key</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                <Tr>
                  <Td>messageid</Td>
                  <Td className="line-break">Message ID is your reference to match and update the Message Status. This is the same ID which was provided in Message Sending API Response.</Td>                  
                </Tr>
                <Tr>
                  <Td>date_time</Td>
                  <Td>  
                      Time when the Status was updated.
                  </Td>
                </Tr>
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>
      </Box>    



		</Box>	
			
		</>
	);
}

export default User;
