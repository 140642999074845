import React,{useState,useEffect} from 'react';
import Header from '../components/Header'
import moment from 'moment'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  MenuDivider,
  Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react'
import { Link,useLocation,useNavigation } from "react-router-dom";
import { fetchWrapper } from '../helpers';
import { getUserFromToken } from '../components/authHelper';

import DailyMessageChart from './chart/daily-msg.chart';
import CountryWiseChart from './chart/country-wise-msg.chart';
import CountryWiseCost from './chart/country-wise-cost.chart';

const Dashboard = ()=>{
	const [listTrans, setListTrans]= useState([])
	const [listLog, setListLog]= useState([])
	const [dailymsg, setDailymsg]= useState([])
	const [countrymsg, setCountrymsg]= useState([])
	const [countryCost, setCountryCost]= useState([])

	useEffect(()=>{
		const user = getUserFromToken();
		const id = user.customer_id
		loadTrans(id);
		loadLog(id);
		loadChart(id);

	},[])

	const loadTrans = async(id) =>{
		const url = `${process.env.REACT_APP_API_URL}/transaction/list?customer_id=${id}`;
      	const data  = await fetchWrapper.get(url)
     	 setListTrans(data)
	}

	const loadLog = async(id) =>{
		const url = `${process.env.REACT_APP_API_URL}/msg-log/list?customer_id=${id}`;
      	const data  = await fetchWrapper.get(url)
     	 setListLog(data)
     	 // console.log(data)
	}


	const loadChart = async(id) =>{
		const url = `${process.env.REACT_APP_API_URL}/msg-log-chart?customer_id=${id}`;
      	const data  = await fetchWrapper.get(url)

     	 setDailymsg(data.Dailymsg)
     	 setCountrymsg(data.CountryWiseCount)
     	 setCountryCost(data.CountryWiseCost)

	}


	
	
	return (
		<>
			<Header/>

			<Box className="body_section">
				<Box mb="10px">
          <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Dashboard</Text>
        </Box>


				<Box className="analytics_panel_section">
					<HStack mb="30px" >
								<Box className="analytics_box_section">
									<Heading>€ {listTrans?.totalCR?.toFixed(4)}</Heading>
								
									<Text>Total Top-up <span>(Till Date)</span></Text>
								</Box>

								<Box className="analytics_box_section">
									<Heading>€ {listTrans?.totalDR?.toFixed(4)}</Heading>
								
									<Text>Total Used <span>(Till Date)</span></Text>
								</Box>

								<Box className="analytics_box_section">
									<Heading>€ {listTrans?.total?.toFixed(4)}</Heading>
								
									<Text>Current Balance</Text>
								</Box>
							</HStack>

							<HStack mb="30px">
								<Box className="analytics_box_section">
									<Heading>{listLog?.data?.length}</Heading>
								
									<Text>Messages Request</Text>
								</Box>

								<Box className="analytics_box_section">
									<Heading>{listLog?.msgSentCount}</Heading>
								
									<Text>Messages Successfully Delivered</Text>
								</Box>

								<Box className="analytics_box_section">
									<Heading>{listLog?.msgFail}</Heading>
								
									<Text>Messages Failed</Text>
								</Box>
							</HStack>	

							<Box className="chart_box">
								<DailyMessageChart datalist={dailymsg}/>
							</Box>

							<Box className="chart_box">
								<CountryWiseChart datalist={countrymsg}/>
							</Box>

							<Box className="chart_box">
								<CountryWiseCost datalist={countryCost}/>
							</Box>
				</Box>
			</Box>
		</>
		)
}

export default Dashboard