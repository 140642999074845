import React from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import List from './user/List'
import Header from '../components/Header'
import { getUserFromToken } from '../components/authHelper';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  MenuDivider,
  Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react'

function User(){
	const user = getUserFromToken();
    const id = user.customer_id   
	return(
		<>
		<Header/>
		<Box className="body_section">
			<List customer_id={id}/>
		</Box>	
			
		</>
	);
}

export default User;