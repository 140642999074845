import React,{useState,useEffect} from 'react';
import Pagination from "react-js-pagination";

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Input 
} from '@chakra-ui/react'
import CustomModalLink from '../components/CustomModalLink'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../helpers';
import moment from 'moment';
import Header from '../components/Header'
import { getUserFromToken } from '../components/authHelper';
import CreateWebHook from './CreateWebHook';
import WebhookView from './WebhookView';


function List() {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [list , setList]= useState([])
  const [date , setDate]= useState('')

  const [loading,setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 

  const [customer , setCustomer]= useState('')


  const [modelHeader , setModelHeader]= useState('')
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();

  useEffect(()=>{
     const user = getUserFromToken();
     setCustomer(user)
    const id = user.customer_id   
    loadlist(id,date);

   
  },[date,activePage])

  const loadlist = async(id,date="")=>{
    setLoading(true)
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : "";
    
    const url = `${process.env.REACT_APP_API_URL}/webhook/list?customer_id=${id}&date=${date}&page=${activePage}`;

    const data  = await fetchWrapper.get(url);
    setList(data.rows);
    setListCount(data.count)
    setLoading(false)
  }

   const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      // console.log(pageNumber);
  };

    const meCloseEdit = () => {
    setIsOpenEdit(false)
     // onClose();    
  }

  const meVeiw = (type ,rs) => {
    if(type === "veiw_data"){
      setIsOpenEdit(true);
      setModelHeader('Data Posted')
      setModalEditView(<WebhookView  meCloseEdit={meCloseEdit} rs={rs}  type={type} />);
    }

    if(type === "veiw_response"){
      setIsOpenEdit(true);
      setModelHeader('Resposne')
      setModalEditView(<WebhookView  meCloseEdit={meCloseEdit} rs={rs} type={type} />);
    }
    
  }


  return(
    <>

     <CustomModalLink
          showModalButtonText={modelHeader}
          modalHeader={modelHeader}
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />

    {loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
    <Header/>
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Webhook Logs</Text>
          </Box>



          <Box>
            <HStack>
              <Box>
                <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
              </Box>

             </HStack>
            
          </Box>
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                 <Th>Date & Time</Th>
                  <Th>Customer</Th>
                  <Th>Message ID</Th>
                  <Th>Url</Th>
                  <Th>Data Posted</Th>
                  <Th>Response</Th>



                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                {list.map((rs, index) => {
                  return(
                    <>
                      <Tr key={index}> 
                    <Td>{moment(rs.created_at).format('DD/MM/YY | HH:mm')}</Td>
                    <Td>{rs?.Customer?.business_name}</Td>
                    <Td>{rs.message_id}</Td>
                    <Td>{rs.url}</Td>
                    <Td onClick={()=> meVeiw('veiw_data',rs)} style={{color:'#1F5AF4' ,cursor:'pointer'}}> Veiw</Td>
                    <Td onClick={()=> meVeiw('veiw_response',rs)} style={{color:'#1F5AF4' ,cursor:'pointer'}}>Veiw</Td>

                  </Tr>
                    </>
                  )
                  
                })}
              </Tbody>
             
              
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default List;