import "prismjs/themes/prism-tomorrow.css";
import Prism from 'prismjs';
import React,{useEffect,useState} from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import List from './user/List'
import Header from '../components/Header'
import { getUserFromToken } from '../components/authHelper';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  MenuDivider,
  Link,
  Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react'
import "prismjs/components/prism-json"; // Ensure JSON support is loaded
import { fetchWrapper } from '../helpers';

function User(){
  const [list,setList]= useState([])
  const data = { "to" : "[Number Goes Here]" , "message" : "[Message Goes Here]" }

  const responsedata = {
        "statusCode": 200,
        "status": "accepted",
        "messageid": "5e8c48b3-540a-4f43-ac8a-cc2a6a2114d6",
        "accepttime": 1727247788
}


	const user = getUserFromToken();
    const id = user.customer_id ;


      useEffect(() => {
        Prism.highlightAll(); 
        loadList(id)
      }, [id]); 

      const loadList = async(id)=>{
        console.log(id)
         const url = `${process.env.REACT_APP_API_URL}/customer/by-id?id=${id}`;
          const data  = await fetchWrapper.get(url)
          setList(data.data)
          // console.log(data.data);
      } 

	return(
		<>
		<Header/>
		<Box className="body_section" style={{width:'70%'}}>
			<Box mb="20px">
        <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">API Documentation</Text>
      </Box>

      <HStack mb="20px">
        <Box>
          <Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">End Point :</Text>
        </Box>
        <Box>
          <Link color="#205EFF" fontSize="16px" fontWeight="400" my="0px" fontFamily="'Inter', sans-serif" href="#">https://api.verifapp.com/send</Link>
        </Box>
      </HStack>

      <HStack  mb="20px">
        <Box>
          <Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Method : </Text>
        </Box>
        <Box>
        <Badge borderRadius="30px" fontSize="14px" px="30px" py="5px" fontWeight="600" bg="#FF9A00" color="white" my="0px" fontFamily="'Inter', sans-serif">post</Badge>

        </Box>
      </HStack>

      <Box mb="20px">
       <div className="table_section">
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Headers</Text>
          </Box>
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Header</Th>
                  <Th>Value</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                <Tr>
                  <Td>Content-Type</Td>
                  <Td>application/json</Td>
                  <Td>Specifies the format of the payload.</Td>
                </Tr>
                <Tr>
                  <Td>x-api-key</Td>
                  <Td>  
                      <Text>{list?.api_key}</Text>
                      <Text fontSize="12px">This is your API Key</Text>

                  </Td>
                  <Td>Your unique API key for authentication.</Td>
                </Tr>
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>
      </Box>  



      <Box mb="20px">
        <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Request Payload</Text>
         <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
          The request payload must be sent in JSON format, containing the recipient's number and the message text.
         </Text>

          <pre style={{ backgroundColor: 'black', textShadow: 'none', color: 'white', padding: '0px', borderRadius: '8px' }}>
              <span style={{padding: 15,backgroundColor: '#2F2F2F',fontSize: '14px', display: 'block', marginBottom: '10px' }}>json</span>
              <code className="language-json" style={{padding: '0px 15px 15px',display: 'block' }}>
                {JSON.stringify(data, null, 2)}
              </code>
            </pre>


      </Box>



        <Box mb="20px">            
       <div className="table_section">
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Payload Fields</Text>
          </Box>
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Field</Th>
                  <Th>Type</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                <Tr>
                  <Td>to</Td>
                  <Td>String</Td>
                  <Td>
                    <Text>The recipient's phone number.</Text>
                    <Text>Important: The number must be provided without a + sign.</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>message</Td>
                  <Td>String</Td>
                  <Td>
                    <Text>The message text to be sent.</Text>
                  </Td>
                </Tr>
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>            
        </Box>




        <Box mb="20px">
          <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Response</Text>
           <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
            If the message is successfully sent, you will receive a response like this:
           </Text>

            <pre style={{ backgroundColor: 'black', textShadow: 'none', color: 'white', padding: '0px', borderRadius: '8px' }}>
              <span style={{padding: 15,backgroundColor: '#2F2F2F',fontSize: '14px', display: 'block', marginBottom: '10px' }}>json</span>
              <code className="language-json" style={{padding: '0px 15px 15px',display: 'block' }}>
                {JSON.stringify(responsedata, null, 2)}
              </code>
            </pre>
        </Box>


        <Box mb="20px">            
       <div className="table_section">
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">API Response Code</Text>
          </Box>
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Status</Th>
                  <Th>Status Code</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                <Tr>
                  <Td>accepted</Td>
                  <Td>200</Td>
                  <Td>
                    Message has been accepted and will be sent to Customer
                  </Td>
                </Tr>
                <Tr>
                  <Td>Content Missing</Td>
                  <Td className="red">404</Td>
                  <Td>
                    message Field sent in Payload in Blank
                  </Td>
                </Tr>
                <Tr>
                  <Td>Phone Number Missing</Td>
                  <Td className="red">404</Td>
                  <Td>
                    to Field sent in Payload in Blank
                  </Td>
                </Tr>
                <Tr>
                  <Td>Authentication Failed</Td>
                  <Td className="red">404</Td>
                  <Td className="line-break">
                    API Key Missing, Invalid API Key or API Key does not Exist in our Database.
                  </Td>
                </Tr>
                <Tr>
                  <Td>Account Inactive</Td>
                  <Td className="red">404</Td>
                  <Td>
                   Customer Account Mark as Inactive in the System
                  </Td>
                </Tr>
                <Tr>
                  <Td>Insufficient balance</Td>
                  <Td className="red">404</Td>
                  <Td className="line-break">
                   Customer do not have enough balance available or has reached their credit limit
                  </Td>
                </Tr>
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>            
        </Box>


        <Box mb="20px">
       <div className="table_section">
           <Box mb="10px">
            <Text fontSize="20px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Other Information</Text>
          </Box>
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Key</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                <Tr>
                  <Td>message-id</Td>
                  <Td>An auto generated unique 36 alpha numeric string</Td>                  
                </Tr>
                <Tr>
                  <Td>accepttime</Td>
                  <Td>  
                      The time we received and accepted the message request
                  </Td>
                </Tr>
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>
      </Box> 


        <Box mb="30px">
          <Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Notes</Text>


        
          <Box>
              <HStack>  
                  <Box w="10px" h="10px" bg="#000000" borderRadius="50%">

                  </Box>
                  <Box>
                    <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
                    The phone number must be provided in international format without the + sign. For example, 447562914341 for a UK number.
                    </Text>
                  </Box>
              </HStack>
          </Box>

          <Box>
              <HStack>  
                  <Box w="10px" h="10px" bg="#000000" borderRadius="50%">

                  </Box>
                  <Box>
                    <Text fontSize="14px" fontWeight="400" color="#000000" my="0px" fontFamily="'Inter', sans-serif">
                      Message must contain an OTP Code
                    </Text>
                  </Box>
              </HStack>
          </Box>


        </Box>
       



		</Box>	
			
		</>
	);
}

export default User;
