import { jwtDecode } from 'jwt-decode';




export const getUserFromToken = () => {
  const token = localStorage.getItem('customer-portal');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.userdata;
  }
  return null;
};